export default {
    PRODWS_URL: process.env.REACT_APP_PRODWS_AFREESPACE_URL ,
    TIMWS_URL: process.env.REACT_APP_TIMWS_AFREESPACE_URL ,
    SPOTWS_URL: process.env.REACT_APP_SPOT_AFREESPACE_URL ,
    ELECTRIC_URL: process.env.REACT_APP_ELECTRIC_IMP_URL ,
    UPLOAD_URL: process.env.REACT_APP_UPLOAD_AFREESPACE_URL ,
    ELECTRIC_AGENT_URL: process.env.REACT_APP_ELECTRIC_IMP_AGENT_URL ,
    LOGIN_BASE_URL: process.env.REACT_APP_LOGIN_BASE_URL,
    PROD_REFERRER: process.env.REACT_APP_PROD_REFERRER_URL ,
    DEV_REFERRER: process.env.REACT_APP_DEV_REFERRER_URL,
    LOCALHOST_REFERRER: process.env.LOCALHOST_REFERRER_URL ,
    WEB_SOCKET_URL: process.env.REACT_APP_WEB_SOCKET_URL ,
}