import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useCallback, useRef } from 'react';
import { useMediaQuery } from 'react-responsive'
import AuthService from "../../Auth/auth";
import HeaderSecondary from "../../components/headerSecondary/HeaderSecondary";
import InfoBox from "../../components/infoBox/InfoBox";
import CautionIcon from "../../assets/images/caution.svg"
import carbonUserInfo from "../../assets/images/carbonUserInfo.svg"
import { SpotWSS } from 'wf-utils/spot/spot-wss';
import { toJpeg } from 'html-to-image';
import _ from 'lodash';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import * as htmlToImage from 'html-to-image';

import "./live-grid.css"
import cameraIcon from "../../assets/images/camera.svg";
import tim from "../../assets/images/timIcon.png";
import infoIcon from "../../assets/images/infoIcon.svg"
import BottomGrid from "../../components/BottomGrid";
import BottomGrid2 from "../../components/BottomGrid2";
import BottomGrid3 from "../../components/BottomGrid3";
import Button1 from "../../components/Button1";

// import BottomGrid2 from "../../components/BottomGrid2";
// import BottomGrid3 from "../../components/BottomGrid3";

import Loader from "../../components/Loader/Loader";
import ButtonViewTest from "../../components/ButtonViewTest";
import Mod1 from "../../components/mod1/Mod1";

function LiveGridTimpTest() {

    const navigate = useNavigate()
    const location = useLocation()
    // console.log("location state", location.state)
    let floorId = location.state.dataForTest.data.locationId;
    let floorName = location.state.dataForTest.data.locationName;

    let room = location.state.dataForTest.data.spaceName;
    let spaceId = location.state.dataForTest.data.spaceId;
    let assetId = location.state.dataForTest.data.assetId;
    // console.log("assetId",assetId)
    let title = 'Test';
    let spotData = location.state.dataForTest.data.spotData;
    let macId = location.state.dataForTest.data.macId;
    let deviceId = location.state.dataForTest.data.deviceId;
    let agentId = location.state.dataForTest.data.agentId;

    let markerId = location.state.dataForTest.data.markerId || null;

    const maxValid = 4;
    const minValid = 1;
    //let people_count = []   //count of people we get from server
    // console.log("spotDta",spotData);

    const [imgJson, setImgJson] = useState(null);
    let [angle, setAngle] = useState(null);// this is for orientation 
    let [heightInput, setHeightInput] = useState(null); // grid view change should be 0.1
    let [data, setData] = useState(null);
    let [reset, setReset] = useState(true);

    let [x, setX] = useState(0);
    let [y, setY] = useState(0);
    let [scalingF, setScalingFactor] = useState(1);

    const [loading, setloading] = useState(true);

    const [infoText, setInfoText] = useState({});
    const [dataForGrid, setDataForGrid] = useState([])
    const [minMax, setMinmax] = useState({})
    const [gridLoad, setGridLoad] = useState(1);
    const [testingData, settestingData] = useState({ current: location.state.dataForTest.test, totalTested: 0 });
    const [snapshot, setSnapShot] = useState(false)
    const [show, setShow] = useState(false);
    const [getNumberToTest, setGetNumberToTest] = useState(false);
    const [userInput, setUserInput] = useState(maxValid - location.state.dataForTest.test)
    const [countFromSpot, setCountFromSpot] = useState(false)


    // -------------------------------------------------
    //added new state or use full state
    const [isthermalImage, setIsthermalImage] = useState(false);
    const [testStart, setTestStart] = useState(false);
    const [zoneClick, setZoneClick] = useState(false);
    const [occupancyforSelectedZone, setZoneoccupancyforSelectedZone] = useState({});
    const [zoneInfo, setZoneInfo] = useState({});
    const [totalTestPlace, setTotalTestPlace] = useState(0);
    const [testedPlace, setTestedPlace] = useState(null);
    // -------------------------------------------------




    let [pixelslist, setPixelslist] = useState([])
    let [pixelslist_assetid, setPixelslist_assetid] = useState({})
    let [assetlist_pixels, setAssetlist_pixels] = useState({})
    let [selectedChild, setSelectedChild] = useState(null)
    let [selectedChildList, setSelectedChildList] = useState([])
    let [selectedChildPixels, setSelectedChildPixels] = useState([])
    let [editPositionClicked, setEditPositionClicked] = useState(0)

    //using state as global variable and updating them in different functions without setState as dont need this data to show on UI. 
    let [people_count] = useState([]);
    let [number_testers] = useState([]);
    let [test_status] = useState([]);
    let [instalation_status] = useState(0);
    let [link_screenshot] = useState([]);

    const [resetCall, setResetCall] = useState(0);

    const ref = useRef();

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    let sel_pixels = [];
    // console.log("->",isDesktopOrLaptop);
    let desiredHeight = isDesktopOrLaptop ? 500 : (window.innerWidth);
    let desiredWidth = (window.innerWidth);

    function calTimWidth(d) {
        // console.log({ d });
        return parseInt(0.05 * d.width);
    }

    async function setupDone() {

        console.log("setUp Done");
        //console.log("final data", data);
        let key = localStorage.getItem('spaceio-key');
        let utcTimestamp = new Date().getTime();
        // let dataToWrite = [{
        //     "assetId": assetId,
        //     "data": {
        //         "utcEpoch": utcTimestamp,
        //         "setup_status": 1,
        //         "height_set": heightInput,
        //         "orientation_set": data.timResult.angle,
        //         "x_value": x,
        //         "y_value": y,
        //         "cal_status": 2,
        //         "calibration_status": 0,
        //         "device_id ": deviceId,
        //         "marker_id ": markerId,
        //         "number_testers": 1,
        //         "people_count": [
        //             0,
        //             0,
        //             0,
        //             0
        //         ],
        //         "test_status": [
        //             0,
        //             0,
        //             0,
        //             0,
        //             0
        //         ],
        //         "instalation_status": 0,
        //         " link_screenshot ": [
        //             "test"
        //         ]

        //     }
        // }]


        // //console.log(dataToWrite)
        // await AuthService.writeDataToSpot(dataToWrite)
        navigate(`/SelectTimPfloor?floor=${floorId}&name=${floorName}`,
            { state: { ...location.state, "final data": data, } })
    }

    const modalDetails = {
        primaryText: "Number of Testers",
        secondaryText: `Please share with how many people you will be running this test.(max ${maxValid - testingData.totalTested})`,
        icon: infoIcon,
        inputBox: true,
        confirmButtonText: "Done"
    }

    function changeX(v) {
        // console.log("spotData",v,scalingF,(v / scalingF).toFixed(4))
        setX((v / scalingF).toFixed(4));
    }

    function changeY(v) {
        // v = data.originalHeight - v
        // console.log("spotData",v,scalingF,(v / scalingF).toFixed(4))
        setY((v / scalingF).toFixed(4));
    }

    function resetCallback() {
        setResetCall(resetCall + 1);
    }

    const infoTextString = (total) => {
        console.log(total);
        let currTested = testedPlace;


        console.log(currTested, total)
        let word1 = currTested === 1 ? "space" : "spaces"
            // let word2 = total === 1 ? "person" : "people"
            // `Please select the spaces you want to run the test on.`

            `Testing Progress: ${currTested} out of ${totalTestPlace} spaces tested.Great work! You're halfway there. Keep testing until you've completed all ${totalTestPlace} spaces`
        return `Tested ${currTested}/${totalTestPlace} ${word1}, total ${word1} for testing is ${totalTestPlace} `;
    }

    useEffect(() => {
        let text = "";
        if (!isthermalImage && !zoneClick) {
            text = ({ text: "Please notify if you are able to see the thermal image.", icon: CautionIcon, background: "#ff5e5e1a" })
        } else {
            if (testedPlace !== totalTestPlace) {
                text = ({ text: `Testing Progress : ${testedPlace == null ? 0 : testedPlace} out of ${totalTestPlace} ${testedPlace < 2 ? "space" : "spaces"} tested. Keep testing until you've completed all ${totalTestPlace} spaces` })
            } else {
                text = ({ text: `Congratulations! You have successfully tested all ${testedPlace} spaces. Please take a screenshot . ` })
            }
        }
        setInfoText(text);
        // !isthermalImage && !zoneClick ? setInfoText({ text: "Please notify if you are able to see the thermal image.", icon: CautionIcon, background: "#ff5e5e1a" }) : setInfoText({ text: `Tested ${testedPlace}/${totalTestPlace} ${testedPlace < 2 ? "space" : "spaces"}, total ${testedPlace < 2 ? "space" : "spaces"} for testing is ${totalTestPlace} `, icon: carbonUserInfo, background: "rgba(56, 214, 139, 0.1)" })
    }, [isthermalImage, testedPlace, totalTestPlace, zoneClick])

    useEffect(() => {
        console.log("pixelslist---", pixelslist)
    }, [pixelslist])

    useEffect(() => {
        // console.log("pixelslist_assetid---", pixelslist_assetid)
    }, [pixelslist_assetid])

    useEffect(() => {
        console.log("assetlist_pixels---", assetlist_pixels)
    }, [assetlist_pixels])

    useEffect(() => {
        console.log("selectedChildPixels---", selectedChildPixels)
        console.log("selectedChildPixels---", typeof (selectedChildPixels));
    }, [selectedChildPixels])

    useEffect(() => {
        console.log("testedPlace---", testedPlace)
    }, [testedPlace])

    useEffect(() => {
        console.log("\n-----------------------------------------------");
        console.log("selectedChild--- 1", selectedChild)
        if (selectedChild && !testStart) {
            // setSelectedChildPixels(assetlist_pixels[selectedChild])
            if (selectedChildList.includes(selectedChild)) {
                console.log("Alredy we have data for : ", selectedChild)
            }
            else {
                selectedChildList.push(selectedChild);
                console.log("selectedChildList", selectedChildList);

                setSelectedChildList(selectedChildList)
                console.log("assetlist_pixels", assetlist_pixels);
                console.log("len ,", assetlist_pixels.length)


                // selectedChildList.map((asset_id) => {
                //     // sel_pixels.push([...selectedChildPixels]);
                //     // console.log(sel_pixels);
                //     // sel_pixels.push([...assetlist_pixels[asset_id]]);
                //     // console.log(sel_pixels);

                //     sel_pixels = selectedChildPixels + assetlist_pixels[asset_id];

                //     return sel_pixels
                // })

                selectedChildList.map((asset_id) => {
                    // sel_pixels.push([...selectedChildPixels]);
                    // console.log(sel_pixels);
                    // sel_pixels.push([...assetlist_pixels[asset_id]]);
                    // console.log(sel_pixels);

                    console.log(assetlist_pixels[asset_id]);
                    // console.log(sel_pixels);
                    sel_pixels.push(...assetlist_pixels[asset_id])
                    // console.log(sel_pixels);
                    // sel_pixels = selectedChildPixels + assetlist_pixels[asset_id];

                    return sel_pixels
                })
                // sel_pixels = sel_pixels + ','
                console.log("////////////////////////////", sel_pixels);
                console.log("-----------------------------------------------\n");
                setSelectedChildPixels(sel_pixels)
            }
        }


    }, [assetlist_pixels, selectedChild, selectedChildList, testStart])

    // useEffect(() => {
    //     console.log("selectedChildList------------------------- ---------- ------------ --------- ", selectedChildList)
    //     let sel_pixels = []
    //     selectedChildList.map((asset_id) => {
    //         sel_pixels = sel_pixels + assetlist_pixels[asset_id];

    //         return sel_pixels

    //     })
    //     setSelectedChildPixels(sel_pixels)
    // }, [JSON.stringify(selectedChildList)])

    // Need useEffect 
    useEffect(
        function () {
            async function fetchData() {
                let key = window.localStorage.getItem('spaceio-key');
                if (key !== null) {
                    const floorDetails = location.state.dataForTest.data['floorDetails']
                    console.log("here", floorDetails);
                    let image = floorDetails[0].image;
                    let { scalingFactor } = floorDetails[0];
                    setImgJson(floorDetails[0].image);

                    const floorSpace = await AuthService.getFloorSpaces(key, floorId, spaceId);
                    let PolySpaces = floorSpace.filter((space) => space.id === spaceId);

                    const timdbdataresult = await AuthService.getTimDBSpaces(key, spaceId);
                    //console.log("========>>>>", timdbdataresult)
                    if (timdbdataresult.length === 0) {
                        setInfoText({ ...infoText, text: "Tim Marker is not available for this space" });
                        return;
                    }


                    [PolySpaces[0]].forEach(function (o) {
                        //console.log("ooooo", o);
                        let timResult = { ...timdbdataresult[0] };
                        //console.log(timResult);
                        setHeightInput(timResult.heightFromFloor);
                        setAngle(timResult.angle);

                        let timO = null;
                        let timResultCoords = timResult.coordinates;

                        const regex = new RegExp('.*points="([^"]*)".*');

                        let tempMark = null;
                        // if ('child' in location.state) {
                        //     tempMark = location.state.parent[0].marker.data;
                        // } else {
                        //     tempMark = o.marker.data;
                        // }
                        tempMark = o.marker.data;
                        const result = regex.exec(tempMark);
                        //console.log("result", result);

                        if (result && result.length === 2) {
                            const coordinatesStr = result[1];
                            let minX, minY, maxX, maxY;
                            coordinatesStr.trim().split(" ").forEach(pairStr => {
                                const values = pairStr.split(",");
                                const x = parseInt(values[0]);
                                const y = parseInt(values[1]);

                                // const x = 1.4900 * scalingFactor
                                // const y = 1.4863 * scalingFactor
                                if (minX === undefined || x < minX) {
                                    minX = x;
                                }
                                if (minY === undefined || y < minY) {
                                    minY = y;
                                }
                                if (maxX === undefined || x > maxX) {
                                    maxX = x;
                                }
                                if (maxY === undefined || y > maxY) {
                                    maxY = y;
                                }
                            });
                            // console.log("maxxxxx",minX,minY,maxX,maxY)

                            const originalWidth = maxX - minX;
                            const originalHeight = maxY - minY;

                            const dimensions = {
                                x: originalWidth,
                                y: originalHeight
                            };
                            const offset = {
                                x: minX,// - (overlayWidth - factor * originalWidth) / 2,
                                y: minY // - (overlayHeight - factor * originalHeight) / 2,
                            };

                            // console.log({ image });
                            // console.log({ minX, minY, maxX, maxY });

                            let actualHeight = dimensions.y;
                            let scaleH = desiredHeight / actualHeight;

                            let actualWidth = dimensions.x;
                            let scaleW = desiredWidth / actualWidth;

                            const style = {
                                backgroundImage: `url(${image.url})`,
                                width: dimensions.x + 2, // + 2 pixel for clearly
                                height: dimensions.y + 2, //+ 2 pixel for clearly
                                margin: 'auto',
                                backgroundSize: image.width + 2,// + 2 pixel for clearly
                                backgroundPositionX: -1 * offset.x,
                                backgroundPositionY: -1 * (image.height - maxY),
                                // position: 'absolute',
                                outline: '1px solid black',
                                transform: isDesktopOrLaptop ? `scale(${scaleH})` : `scale(${scaleW})`,
                                transformOrigin: isDesktopOrLaptop ? '50% 0' : '0 0'
                            };

                            // timResult.timTempPosition = {
                            //     top: maxY - timO.srf.y,
                            //     left: timO.srf.x - minX
                            // };


                            if (timResultCoords.length > 0) {
                                // let [timX, timY] = timResultCoords.split(',');
                                let [timX, timY] = [spotData.x_value, spotData.y_value]
                                // console.log("bbb", spotData.x_value, spotData.y_value)
                                let timScaleX = (timX * scalingFactor - minX);
                                let timScaleY = (maxY - timY * scalingFactor);
                                timO = {
                                    srf: { x: timScaleX, y: timScaleY }
                                }
                            }

                            timResult.timTempPosition = {
                                top: timO.srf.y,
                                left: timO.srf.x
                            };
                            let temp = {
                                originalWidth,
                                originalHeight,
                                style,
                                timResult,
                                scalingFactor,
                                offset

                            }
                            setData(temp);
                            const img = new Image()
                            img.src = image.url
                            img.onload = () => setloading(false);


                        }
                        else {
                            //console.log('polygon is not created for ', spaceId)
                            setInfoText({ ...infoText, text: 'Polygon is not created for this space! ' + spaceId });
                            setloading(false);
                        }
                    })


                    // // let parentAssetIdArray = ["b94c8e14-d8d2-4236-a23d-32f6ae22a9f5"] //assetId
                    // let parentAssetIdArray = [assetId] //assetId
                    // const timplusspotdata = await AuthService.readTimPDataSPOT(key, parentAssetIdArray)
                    // let timplusspotdata_dict = {}
                    // timplusspotdata.map(item => timplusspotdata_dict[item["id"]] = item)
                    // console.log("timplusspotdata_dict", timplusspotdata_dict)

                    // // var test_zone = timplusspotdata_dict["b94c8e14-d8d2-4236-a23d-32f6ae22a9f5"].test_zone //assetId
                    // var test_zone = timplusspotdata_dict[assetId].test_zone //assetId
                    // setZoneInfo(test_zone);
                    // console.log("test_zone", test_zone)
                    // console.log("test_zone", test_zone.length)

                    // setTotalTestPlace(test_zone.length);

                    // var pixels_arr = []
                    // var allPixels = [];
                    // var allPixels_assetID = {};
                    // var allassetID_pixels = {}

                    // test_zone.map((child) => {
                    //     return [
                    //         pixels_arr.push(child.pixels), // 2d array
                    //         allassetID_pixels[child.asset_id] = child.pixels, //assetID:pixels
                    //         child.pixels.map((pixel) => {
                    //             return [
                    //                 allPixels.push(pixel), //1d array
                    //                 allPixels_assetID[pixel] = child.asset_id // pixel:assetID
                    //             ]
                    //         })
                    //     ]

                    // })
                    // // for(var i = 0; i < pixels_arr.length; i++)
                    // // {
                    // // 	allPixels = allPixels.concat(pixels_arr[i]);
                    // // 	allPixels_assetID[]
                    // // }

                    // // console.log("pixels_arr,", pixels_arr) // 2d array
                    // // console.log("allPixels,", allPixels) //1d array
                    // // console.log("allPixels_assetID,", allPixels_assetID);// pixel:assetID






                    // setPixelslist(allPixels) //1d array =>global
                    // setPixelslist_assetid(allPixels_assetID) // pixel:assetID => global
                    // setAssetlist_pixels(allassetID_pixels) ///assetID:pixels => global


                }
            }
            fetchData();

        }, [assetId, desiredHeight, desiredWidth, floorId, infoText, isDesktopOrLaptop, location.state, spaceId, spotData.x_value, spotData.y_value])

    useEffect(
        function () {
            async function fetchData() {
                let key = window.localStorage.getItem('spaceio-key');
                // let parentAssetIdArray = ["b94c8e14-d8d2-4236-a23d-32f6ae22a9f5"] //assetId
                let parentAssetIdArray = [assetId] //assetId
                const timplusspotdata = await AuthService.readTimPDataSPOT(key, parentAssetIdArray)
                
                let timplusspotdata_dict = {}
                timplusspotdata.map(item => timplusspotdata_dict[item["id"]] = item)
                console.log("timplusspotdata_dict", timplusspotdata_dict)

                // var test_zone = timplusspotdata_dict["b94c8e14-d8d2-4236-a23d-32f6ae22a9f5"].test_zone //assetId
                var test_zone = timplusspotdata_dict[assetId].test_zone //assetId
                setZoneInfo(test_zone);
                console.log("test_zone", test_zone)
                console.log("test_zone", test_zone.length)

                setTotalTestPlace(test_zone.length);

                var pixels_arr = []
                var allPixels = [];
                var allPixels_assetID = {};
                var allassetID_pixels = {}

                test_zone.map((child) => {
                    return [
                        pixels_arr.push(child.pixels), // 2d array
                        allassetID_pixels[child.asset_id] = child.pixels, //assetID:pixels
                        child.pixels.map((pixel) => {
                            return [
                                allPixels.push(pixel), //1d array
                                allPixels_assetID[pixel] = child.asset_id // pixel:assetID
                            ]
                        })
                    ]

                })
                // for(var i = 0; i < pixels_arr.length; i++)
                // {
                // 	allPixels = allPixels.concat(pixels_arr[i]);
                // 	allPixels_assetID[]
                // }

                // console.log("pixels_arr,", pixels_arr) // 2d array
                // console.log("allPixels,", allPixels) //1d array
                // console.log("allPixels_assetID,", allPixels_assetID);// pixel:assetID
                setPixelslist(allPixels) //1d array =>global
                setPixelslist_assetid(allPixels_assetID) // pixel:assetID => global
                setAssetlist_pixels(allassetID_pixels) ///assetID:pixels => global
            }
            fetchData();
        }, [])

    useEffect(() => {
        let parentAssetId = location.state.dataForTest.data["assetId"];
        // let unsubscribe ;
        let unsubscribePromise;

        async function fetchParentAssetId() {
            // const sleep = ms => new Promise(resolve => setTimeout(resolve,ms))
            // await sleep(10000)

            parentAssetId = await AuthService.getAssetId(location.state.dataForTest.data["assetId"].spaceId);
            parentAssetId = parentAssetId.assetId;
            location.state.parent[0]["assetId"] = parentAssetId;
            //console.log("parentAssetId", parentAssetId)
            return subscribeSpot(handleSpotData, parentAssetId, 23);

        }

        if (!parentAssetId) {
            unsubscribePromise = fetchParentAssetId()
        }
        else {
            unsubscribePromise = Promise.resolve(subscribeSpot(handleSpotData, parentAssetId, 23))
        }

        return async () => {
            const unsubscribe = await unsubscribePromise
            unsubscribe();
            await AuthService.defaultFrameRate(agentId)
        };
    }, [])

    useEffect(() => {
        // Need to show snap button so logic is given below 
        console.log("Snap try", totalTestPlace, testedPlace)
        if (totalTestPlace === testedPlace) {
            console.log("Now we need to give Snap sort button ");
            setShow(true);
        }
    }, [testedPlace, totalTestPlace])


    const subscribeSpot = (onData, assetId, templateId = 23) => {
        // console.log('------------------ :', assetId);
        const subscriptions = [{ assetId: assetId, templateId: templateId }];
        let key = window.localStorage.getItem('spaceio-key');

        const spotWs = new SpotWSS({
            subscriptions,
            wsUrl: process.env.WEB_SOCKET_URL,
            token: key,
            onData,
        });

        return () => {
            spotWs.disposeConnection();
        };
    };

    const handleSpotData = ({ data }) => {
        // console.log("dataForGrid", data.device_id, macId)
        // console.log(gridLoad)
        // if(gridLoad === 1) setloading(true)
        // console.log("handleSpotData data =>",data)
        // console.log(location.state.dataForTest.data)
        if (data.deviceId === deviceId) {

            let pixelData = JSON.parse(data.pixel_data)
            pixelData = [].concat(...pixelData)

            let calibrationData = JSON.parse(data.calibration_data)
            calibrationData = [].concat(...calibrationData)

            let arr = []
            //console.log(pixelData, calibrationData)
            for (let i = 0; i < pixelData.length; i++) {
                // console.log((pixelData[i] || 0) , (calibrationData[i] || 0),"  " ,(pixelData[i] || 0) - (calibrationData[i]))
                const el = ((pixelData[i] || 0) - (calibrationData[i] || 0));
                arr[i] = el;
            };

            let minMax = {}
            minMax["min"] = (Math.min(...arr))
            minMax["max"] = (Math.max(...arr))
            //console.log("dataForGrid", minMax)
            setMinmax(minMax)
            setDataForGrid(arr)
            // setGridLoad(gridLoad + 1)
            // setloading(false)
        }

    };
    // const infoTextStringDuringTesting = () => {

    // }

    const getCountFromSpot = async () => {
        console.log("inside getCountFromSpot")
        setloading(true)
        // let parentAssetId = location.state.parent[0]["assetId"];

        // if (!parentAssetId) {
        //     parentAssetId = await AuthService.getAssetId(location.state.parent[0].id);
        //     parentAssetId = parentAssetId.assetId;
        //     location.state.parent[0]["assetId"] = parentAssetId;
        // }
        // let assetIdForCount = parentAssetId ? parentAssetId : assetId
        // const peopleCount = await AuthService.getCountFromSpot([assetIdForCount])

        // // console.log("peopleCount", peopleCount[0]["count"], assetIdForCount)
        // // console.log(testingData.current + testingData.totalTested)

        // settestingData((prev) => ({
        //     current: prev.current,
        //     totalTested: prev.totalTested + prev.current
        // }))
        // people_count.push(peopleCount[0]["count"]);
        // number_testers.push(testingData.current)

        // //console.log(people_count)
        // if (testingData.current === peopleCount[0]["count"]) {
        //     setInfoText({ text: `People counted ${peopleCount[0]["count"]}. Test Passed!`, icon: carbonUserInfo, background: "rgba(56, 214, 139, 0.1)" })
        //     test_status.push(1)
        // }
        // else {
        //     setInfoText({ text: `People counted ${peopleCount[0]["count"]}. Test Failed!`, icon: CautionIcon, background: "#ff5e5e1a" })
        //     test_status.push(0)
        // }

        setCountFromSpot(true)
        setSnapShot(true)
        setloading(false)
    }

    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    const getSnapShot = useCallback(async () => {
        setloading(true)
        if (ref.current === null) {
            return
        }
        await toJpeg(ref.current)
            .then(async (dataUrl) => {
                // **download file to verify the snapshot       
                // const link = document.createElement('a')
                // link.download = 'my-image-name.jpg'
                // link.href = dataUrl
                // link.click()
                const date = new Date();
                console.log(location.state.dataForTest.data.floorDetails);
                let fileName =
                    location.state.dataForTest.data.floorDetails[0].name + "_" +
                    ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + date.getFullYear()
                    + "_" +
                    date.toLocaleTimeString([], {
                        hour12: false,
                    }) +
                    ".jpg"
                // console.log("File Name : ", fileName);
                link_screenshot.push(fileName);

                writingInSpot();

                var file = dataURLtoFile(dataUrl, fileName);
                // console.log("file : ", file);
                let isUploadDone = await AuthService.uploadFileTos3(file)
                // console.log("isUploadDone: ", isUploadDone);

                const key = localStorage.getItem("spaceio-key")

                // console.log()
                // console.log("assetId ------------------>", location.state.dataForTest.data.assetId);
                let parentAssetIdArray = [assetId] //assetId
                let spotDataTimp = await AuthService.readTimPDataSPOT(key, parentAssetIdArray) //not working
                // console.log("assetId ------------------>", location.state.dataForTest.data.assetId);
                // console.log("spotDataTimp1", spotDataTimp);
                // console.log("spotDataTimp2", spotDataTimp[0]);

                // console.log("spotDataTimp3", spotDataTimp[0].zone_setup_status);
                spotDataTimp[0].zone_setup_status = 3;
                // console.log("spotDataTimp4", spotDataTimp);

                let dataToBeSend = [{ "assetId": location.state.dataForTest.data.assetId, "data": spotDataTimp[0] }]
                // console.log("dataToBeSend",dataToBeSend)
                // console.log(dataToBeSend);
                let dataSendStraus = await AuthService.writeDataToTIMpSpot(dataToBeSend);
                // console.log("spotDataTimp", dataSendStraus);



                setShow(false)
                setloading(false)

                navigate(`/SelectTimPfloor?floor=${floorId}&name=${floorName}`,
                    { state: { ...location.state, "final data": data, } })

            })
            .catch(err => console.log(err))

    }, [ref])

    async function writingInSpot() {
        // setloading(true)
        // let count = 0;
        // for (let i of test_status) {
        //     if (i === 1) count++
        // }
        // if (count / test_status.length === 1) instalation_status = 1
        // else if (count / test_status.length >= 0.5) instalation_status = 2
        // else instalation_status = 3

        let dataToWrite = [{
            "assetId": assetId,
            "data": {
                ...spotData,
                "people_count": [],
                "number_testers": 0,
                "test_status": [],
                "instalation_status": 1,
                "link_screenshot": link_screenshot
            }
        }]
        //console.log("write to spot", dataToWrite)
        await AuthService.writeDataToSpot(dataToWrite)
        // setloading(false)
        // navigate("/all-tim",
        //     { state: { ...location.state }, replace: true })

    }

    const uploadSnapAndNextTest = async () => {

        console.log("Inside uploadSnapAndNextTest");
        // getSnapShot().then(() => {

        //     if (testingData.totalTested < maxValid) {
        //         setGetNumberToTest(true)
        //         setInfoText({ text: infoTextString(testingData.current, testingData.totalTested), icon: carbonUserInfo, background: "rgba(56, 214, 139, 0.1)" })
        //         setCountFromSpot(false)
        //     }
        //     else {
        //         writingInSpot()
        //     }
        // })

    }

    // const testNextSpace = async () => { setloading(true)
    //     let parentAssetId = location.state.parent[0]["assetId"];

    //     if (!parentAssetId) {
    //         parentAssetId = await AuthService.getAssetId(location.state.parent[0].id);
    //         parentAssetId = parentAssetId.assetId;
    //         location.state.parent[0]["assetId"] = parentAssetId;
    //     }    
    //     let assetIdForCount = parentAssetId ? parentAssetId : assetId
    //     const peopleCount = await AuthService.getCountFromSpot([assetIdForCount])

    //     console.log("peopleCount",peopleCount[0]["count"],assetIdForCount)
    //     console.log(testingData.current+testingData.totalTested)

    //     settestingData((prev)=>({
    //         current:prev.current,
    //         totalTested:prev.totalTested + prev.current
    //     }))
    //     people_count.push(peopleCount[0]["count"]);
    //     number_testers.push(testingData.current)

    //     console.log(people_count)
    //     if(testingData.current === peopleCount[0]["count"]){
    //         setInfoText({text:`People counted ${peopleCount[0]["count"]}. Test Passed!`,icon:carbonUserInfo,background:"rgba(56, 214, 139, 0.1)"}) 
    //         test_status.push(1)
    //     }
    //     else{
    //         setInfoText({text:`People counted ${peopleCount[0]["count"]}. Test Failed!`,icon:CautionIcon, background:"#ff5e5e1a"})
    //         test_status.push(0)
    //     }

    //     setCountFromSpot(true)
    //     setSnapShot(true)
    //     setloading(false)

    // }


    const setModalDetailsAfterCheck = () => {
        let val = userInput
        if (userInput >= minValid && userInput <= maxValid - testingData.totalTested) {
            settestingData({ ...testingData, current: val })
            setGetNumberToTest(false)
            setInfoText({ ...infoText, text: infoTextString(val, testingData.totalTested) })
            setUserInput(minValid)
        }
    }

    const checkValueForTest = (value) => {
        let val = parseInt(value)
        setUserInput(val)
        setTimeout(function () {
            if (val < minValid) setUserInput(minValid)
            else if (val > maxValid - testingData.totalTested) setUserInput(maxValid - testingData.totalTested)
            // if(value >= minValid && value <= maxValid-testingData.totalTested) setUserInput(parseInt(value))
        }, 1500);
    }

    const getOccupancyData = async () => {
        setloading(true);
        setZoneClick(false);
        const key = localStorage.getItem("spaceio-key");
        if (key != null && selectedChildList.length > 0) {
            const occupancyData = await AuthService.getOccupanceDataforTIMP(key, selectedChildList);
            setZoneoccupancyforSelectedZone(occupancyData);
        }
        else {
            toast.error("Please select at least one zone", {
                position: "top-right",
                autoClose: 3000, // Auto-close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            setTestStart(false);
            setZoneClick(zoneClick)
            // setIsthermalImage(false);
              

            // setTestStart(true);
        }
        setloading(false);
    }

    const testMoreSpace = async () => {
        // console.log("inside testMoreSpace");
        setZoneClick(true);
        setTestStart(false);
    }

    const unselectAll = async () => {
        setloading(true);
        setSelectedChildList([]);
        setSelectedChildPixels('');
        setSelectedChild(null);
        setTestedPlace(0);
        setShow(false);
        sel_pixels = [];
        setloading(false);
    }

    const uploadScreenSort = async () => {
        // console.log("Inside upload Screen Sort ............ ");
        getSnapShot().then(() => {
            console.log("took Screen Sort ............ ");
        })
    }

    return (<>
        {loading && <Loader />}
        <div className="container-fluid g-0">
            <div className="row justify-content-center g-0 mainDiv">
                <div ref={ref} className="col-sm-12 col-lg-8 position-relative" style={{ background: "#ffffff" }}>
                    <div className="row g-0 snapShotClass" style={{ height: "100%", display: "flex", flexDirection: "column" }}>

                        {/* {getNumberToTest ? <Mod1 text={{ primaryText: modalDetails.primaryText, secondaryText: modalDetails.secondaryText, icon: modalDetails.icon }}
                            // closeModal={() => setGetNumberToTest(false) }
                            confirmButton={{ text: modalDetails.confirmButtonText, onClick: setModalDetailsAfterCheck }}
                            inputBox={modalDetails.inputBox}
                            value={modalDetails.inputBox ? userInput : null}
                            setValue={modalDetails.inputBox ?
                                checkValueForTest
                                : null}
                        /> : null} */}

                        <div className="col-12" style={{ background: "#F5F5F5", padding: '0 5px' }}>
                            <div className="row g-0">
                                <div className="col-11 mx-auto" style={{ background: "#F5F5F5" }}>
                                    <HeaderSecondary
                                        secondaryText={title}
                                        primaryText={room}
                                        goBack={() => navigate(-1)}
                                        tabInd={0}
                                        buttonSecondary={show && zoneClick ? <button style={{ border: "none", background: "none" }} onClick={() => { uploadScreenSort() }}><img src={cameraIcon} alt="snapshot" crossOrigin="anonymous" /></button> : null}
                                    />
                                </div>
                            </div>
                        </div>
                        <></>
                        <div className="col-11 mx-auto my-4">
                            <InfoBox text={infoText.text} image={infoText.icon} background={infoText.background} confirm={!isthermalImage ? () => { setIsthermalImage(!isthermalImage); setZoneClick(!zoneClick); } : null} cancel={() => navigate(-1)} />
                        </div>

                        <div className="col-11.5 mx-auto" style={{ marginTop: reset ? '' : '30px', position: 'relative', height: desiredHeight }}>
                            {imgJson && data && <div id="arena" style={data.style}>
                                {/* <img src={imgJson.url} crossOrigin="anonymous"/> */}
                                <div id="tim_marker">
                                    <GridView
                                        data={data}
                                        userHeight={spotData.height_set}
                                        userAngle={spotData.orientation_set}
                                        dataForGrid={dataForGrid} minMax={minMax}
                                        pixelslist={pixelslist}
                                        setSelectedChild={setSelectedChild}
                                        pixelslist_assetid={pixelslist_assetid}
                                        selectedChildPixels={selectedChildPixels}
                                        reset={false} isthermalImage={isthermalImage}
                                        zoneClickFlag={zoneClick}
                                        occupancyforSelectedZone={occupancyforSelectedZone}
                                        selectedChildList={selectedChildList}
                                        zoneInfo={zoneInfo}
                                        settestingData={settestingData}
                                        testingData={testingData}
                                        setTestedPlace={setTestedPlace}
                                    />
                                </div>
                                <div role="img" aria-label="tim" id="tim_marker_test" style={{
                                    position: 'absolute',
                                    top: `${data.timResult.timTempPosition.top}px`,
                                    left: `${data.timResult.timTempPosition.left}px`,
                                    transform: `rotate(-${angle}deg)`,
                                    height: '0px',
                                    width: '0px'
                                }}>
                                    {/*this is tim marker */}
                                    <img src={tim} alt="tim" style={{
                                        margin: 0,
                                        position: 'absolute',
                                        top: '50 %',
                                        left: '50 %',
                                        transform: 'translate(-50%, -50%)',
                                        width: calTimWidth(data.style) || '20px',
                                        height: calTimWidth(data.style) || '20px'
                                    }} />
                                </div>

                            </div>
                            }
                        </div>
                        <br />
                        {isthermalImage ? testStart ?
                            <div style={{ background: "#f5f5f5", display: "flex", alignItems: "center", margin: "auto 0 0rem", minHeight: "42px" }}>
                                {< ButtonViewTest text="Rerun Test" borderRadius="0px" borderColor="#ff9170" background="white" color="#ff9170" onClick={() => { getOccupancyData(); }} />}
                                < ButtonViewTest text="Test More Spaces" borderRadius="0px" borderColor="transparent" background="linear-gradient(90deg, #F7707D 0%, #F29173 100%)" color="white" onClick={() => { testMoreSpace(); }} />
                            </div>
                            :
                            <div style={{ background: "#f5f5f5", display: "flex", alignItems: "center", margin: "auto 0 0rem", minHeight: "42px" }}>
                                {/* < ButtonViewTest text="Unselect All" borderRadius="0px" borderColor="#ff9170" background="white" color="#ff9170" onClick={() => { unselectAll(); }} /> */}
                                {show ?
                                    <ButtonViewTest text={"Take SnapShot"} borderRadius="0px" borderColor="transparent" background="linear-gradient(90deg, #F7707D 0%, #F29173 100%)" color="white"
                                        onClick={() => { uploadScreenSort(); }} />
                                    : < ButtonViewTest text="Test" borderRadius="0px" borderColor="#ff9170" background="linear-gradient(90deg, #F7707D 0%, #F29173 100%)" color="white" onClick={() => { setTestStart(true); getOccupancyData(); }} />}
                            </div>
                            :
                            null
                        } 
                    </div>
                </div>
            </div>
        </div>
    </>);
}
{/* <div style={{ background: "#f5f5f5", display: "flex", alignItems: "center", margin: "auto 0 0rem", minHeight: "42px" }}>
    {show && <ButtonViewTest text="Rerun Test" borderRadius="0px" borderColor="#ff9170" background="white" color="#ff9170" onClick={() => navigate(-1)} />}
    {!countFromSpot ? <ButtonViewTest text={show ? "Test Next Space" : "Test Current Space"} borderRadius="0px" borderColor="transparent" background="linear-gradient(90deg, #F7707D 0%, #F29173 100%)" color="white"
        onClick={show ? () => uploadSnapAndNextTest() : () => testNextSpace()} /> :
        <ButtonViewTest text={"Testing Nxt"} borderRadius="0px" borderColor="transparent" background="linear-gradient(90deg, #F7707D 0%, #F29173 100%)" color="white"
            onClick={() => { uploadSnapAndNextTest() }} />
    }

</div> : null */}
export default LiveGridTimpTest;

function GridValue(left, top, scalingFactorMeter, rotationAngleFromInput, heightFromFloor, minX, minY, pixelslist, selectedChildPixels, isthermalImage, zoneClickFlag, zoneInfo) {

    function setCursor(pixel) {
        let cursorstlye = "default";
        if (isthermalImage && zoneClickFlag) {
            cursorstlye = "pointer";
            // return cursorstlye;
        }
        if (!_.isEmpty(zoneInfo)) {
            zoneInfo.forEach(id => {
                if (id.pixels.includes(pixel) && (id.occupancyData === 'PRESENCE')) {
                    cursorstlye = "default";
                }
            })
        }
        return cursorstlye;
    }

    function setClickable(pixel) {
        let ClickableStlye = 0;
        if (isthermalImage && zoneClickFlag) {
            ClickableStlye = 1;
        }
        if (!_.isEmpty(zoneInfo)) {
            zoneInfo.forEach(id => {
                if (id.pixels.includes(pixel) && (id.occupancyData === 'PRESENCE')) {
                    ClickableStlye = 0;
                }
            })
        }
        return ClickableStlye
    }

    var XOfReact = parseFloat(left);
    var YOfReact = parseFloat(top);
    let height = heightFromFloor; // in meter
    let width = 1.534 * height; /* Area width*/
    let length = 2.856 * height / 1.5;/*Area hieght*/
    var rxWidth = width * scalingFactorMeter;//10 * 24;//???
    var ryLength = length * scalingFactorMeter;//10 * 32;//???
    let gridWidth = rxWidth / 24; ////
    let gridLength = ryLength / 32;
    let gridpaint = [];
    let gridpaintArray = [];
    let i = 0;
    let j = 0;
    let p = 1;
    for (j = 0; j < 32; j++) {
        for (i = 0; i < 24; i++) {
            p = (i * 32) + (31 - j)
            gridpaint.push(`
		<g transform="rotate(-${rotationAngleFromInput} ${XOfReact + 15} ${YOfReact + 15}) translate(-${ryLength / 2 - 15} , -${rxWidth / 2 - 15})" >
		<rect  x="${XOfReact + (j * gridLength)}" y="${YOfReact + (i * gridWidth)}" width="${gridLength}" height="${gridWidth}"  style="stroke-width:0.3;" />
		<text font-size="0.1em"  x="${XOfReact + (j * gridLength)}" y="${20 + YOfReact + (i * gridWidth)}" transform="rotate(${rotationAngleFromInput} ${XOfReact + (j * gridLength) + (gridLength / 2)},${YOfReact + (i * gridWidth) + (gridWidth / 2)})"  opacity=".1" >${(i * 32) + (31 - j)}</text>
		</g>
		`)
            let tempData = {
                g: {
                    transform: `rotate(-${rotationAngleFromInput} ${XOfReact} ${YOfReact}) translate(-${ryLength / 2} , -${rxWidth / 2})`
                },
                rect: {
                    x: `${XOfReact + (j * gridLength)}`,
                    y: `${YOfReact + (i * gridWidth)}`,
                    width: `${gridLength}`,
                    height: `${gridWidth}`,
                    style: {
                        strokeWidth: selectedChildPixels.includes(p) ? 1.5 : pixelslist.includes(p) ? 1 : 0,
                        // cursor: pixelslist.includes(p) && isthermalImage && zoneClickFlag ? "pointer" : ""
                        cursor: setCursor(p)
                    },
                    pixel_val: p,
                    // clickble: pixelslist.includes(p) && isthermalImage && zoneClickFlag ? 1 : 0
                    clickble: setClickable(p)
                },
                text: {
                    x: `${5 + XOfReact + (j * gridLength)}`,
                    y: `${18 + YOfReact + (i * gridWidth)}`,
                    transform: `rotate(${rotationAngleFromInput} ${XOfReact + (j * gridLength) + (gridLength / 2)},${YOfReact + (i * gridWidth) + (gridWidth / 2)})`,
                    value: p
                }
            }
            gridpaintArray.push(tempData);
            ;
        }
    }
    // console.log("gridpaintArray",gridpaintArray)
    return [gridpaint, gridpaintArray, rxWidth, ryLength, minX, minY];

}

// const GridView = ({ data, userHeight, userAngle,pixelslist,setSelectedChild,pixelslist_assetid ,selectedChildPixels,reset}) => {
const GridView = ({ data, userHeight, userAngle, dataForGrid, minMax, pixelslist, setSelectedChild, pixelslist_assetid, selectedChildPixels, reset, isthermalImage, zoneClickFlag, occupancyforSelectedZone, selectedChildList, zoneInfo, settestingData, testingData, setTestedPlace }) => {
    // console.log("zoneInfo ........ ", zoneInfo);
    // console.log("zoneClickFlag:", zoneClickFlag)
    // let arr = []
    // for(let i = 0;i<(24*32);i++){
    //     arr.push(undefined)
    // }
    // console.log("pixelslist,setSelectedChild,pixelslist_assetid ,selectedChildPixels,reset", pixelslist, setSelectedChild, pixelslist_assetid, selectedChildPixels, reset)

    function getselected(pixel, pixellist, selectedpixellistt) {
        return selectedpixellistt.includes(pixel) ? 'green' : pixellist.includes(pixel) ? 'blue' : '';
    }

    // function getselectedColor(pixel, pixellist, selectedpixellistt) {
    //     return selectedpixellistt.includes(pixel) ? 'green' : pixellist.includes(pixel) ? 'blue' : '';
    // }

    function fillOpacity(value,) {
        let opacity;
        if (minMax["max"] - minMax["min"] !== 0) {

            opacity = value == null ? 0 : ((((value - minMax["min"]) * (1 - 0)) / (minMax["max"] - minMax["min"])) + 0)

            // opacity = pixelslist.includes(pixel_val) ? opacity : opacity / 5

            let finalPixelView = value > 0 ? `rgba(255, ${255 * (1 - opacity)}, ${255 * (1 - opacity)}, 0.5)` : `rgba(${255 * (1 - opacity)},255, ${255 * (1 - opacity)}, 0.5)`;

            return finalPixelView;

        }
        else return "rgba(0, 128 , 0, 0)"
    }

    function fillOpacityOfLayer(value, pixel_val, selectedpixellistt) {
        let opacity;
        if (minMax["max"] - minMax["min"] !== 0) {

            opacity = value == null ? 0 : ((((value - minMax["min"]) * (1 - 0)) / (minMax["max"] - minMax["min"])) + 0)

            let finalPixelView = value > 0 ? `rgba(255, ${255 * (1 - opacity)}, ${255 * (1 - opacity)}, 0.5)` : `rgba(${255 * (1 - opacity)},255, ${255 * (1 - opacity)}, 0.5)`;

            // finalPixelView = pixelslist.includes(pixel_val) ? (value > 0 ? `rgba(200, ${200 * (1 - opacity)}, ${200 * (1 - opacity)}, 1)` : `rgba(${200 * (1 - opacity)},200, ${200 * (1 - opacity)}, 1)`) : finalPixelView;
            finalPixelView = pixelslist.includes(pixel_val) ? `rgba(0, 0, 0, 0.4)` : finalPixelView;


            finalPixelView = selectedpixellistt.includes(pixel_val) ? `rgba(250,218,94,0.5)` : finalPixelView;


            if (!_.isEmpty(zoneInfo)) {
                zoneInfo.forEach(id => {
                    if (id.pixels.includes(pixel_val) && (id.occupancyData === 'PRESENCE')) {
                        finalPixelView = `rgba(0, 255 , 0, 0.5)`
                        // console.log("yes in else")

                        // settestingData({ ...testingData, current: 4 })
                    }
                })
            }




            if (!zoneClickFlag) {
                selectedChildList.forEach(id => {
                    let matchingObject = null;
                    // console.log(occupancyforSelectedZone.length);
                    for (let i = 0; i < occupancyforSelectedZone.length; i++) {
                        if (occupancyforSelectedZone[i].id === id) {
                            matchingObject = occupancyforSelectedZone[i];
                            break;
                        }
                    }
                    if (matchingObject) {
                        zoneInfo.forEach(id => {
                            if (id.asset_id === matchingObject.id) {
                                // if (id.pixels.includes(pixel_val) && true) {
                                //     finalPixelView = `rgba(0, 255 , 0, 0.5)`;
                                //     id.occupancyData = 'PRESENCE';
                                // }
                                if (id.pixels.includes(pixel_val) && (matchingObject.value === 'PRESENCE')) {
                                    finalPixelView = `rgba(0, 255 , 0, 0.5)`;
                                    id.occupancyData = matchingObject.value;
                                }
                                else if (id.pixels.includes(pixel_val) && (matchingObject.value === 'ABSENCE')) {
                                    if (id.occupancyData === 'PRESENCE') {
                                        finalPixelView = `rgba(0, 255 , 0, 0.5)`;
                                    }
                                    else {

                                        finalPixelView = `rgba(255, 0 , 0, 0.5)`;
                                    }
                                }
                            }
                        })
                    }
                });
            }
            return finalPixelView;
        }
        else return "rgba(0, 128 , 0, 0)"
    }
    // console.log('userAngle', userAngle);
    // console.log('userHeight', userHeight);
    // console.log('GridView', data);
    let { scalingFactor } = data;
    let { left, top } = data.timResult.timTempPosition
    let { angle } = data.timResult;
    let { heightFromFloor } = data.timResult;
    let { x, y } = data.offset;
    // let [returngridpaint, returngridpaintArray, rxWidth, ryLength] = GridValue(left, top, scalingFactor, userAngle, userHeight, x, y,pixelslist,selectedChildPixels);

    // console.log("zoneInfo ........ after ", zoneInfo);

    let testPassCounter = 0;
    zoneInfo.forEach((id) => {
        if (id.occupancyData && id.occupancyData === 'PRESENCE') {
            testPassCounter++;
            setTestedPlace(testPassCounter);
        }
    })


    let [returngridpaint, returngridpaintArray, rxWidth, ryLength] = GridValue(left, top, scalingFactor, userAngle, userHeight, x, y, pixelslist, selectedChildPixels, isthermalImage, zoneClickFlag, zoneInfo);





    return (
        <svg
            width={data.originalWidth} height={data.originalHeight}
        // style={{ transform: `translate(-${rxWidth / 2}px, -${ryLength / 2}px)` }}
        // viewBox={`${x} ${y} ${data.originalWidth} ${data.originalHeight}`}
        // viewBox="10 10 10 10"
        >
            {/* <rect width={rxWidth} height={ryLength} style={{ strokeWidth: 0.3, fill: 'transparent' }} /> */}
            {returngridpaintArray.map(({ g, rect, text }) => {
                // console.log(rect.style.strokeWidth, rect.style.stroke)
                return (
                    // < g transform={g.transform} key={text.value}>
                    < g transform={g.transform} key={text.value} pixel_val={rect.pixel_val} pix={text.value} onClick={() => { if (rect.clickble && !reset) { /*alert(text.value);*/setSelectedChild(pixelslist_assetid[text.value]) } }}>

                        {/* <rect x={rect.x} y={rect.y} width={rect.width} height={rect.height} style={{ strokeWidth: 1, stroke: 'rgba(255, 81, 72,0.1)', fill: fillOpacity(dataForGrid[text.value]) }} /> */}
                        <rect x={rect.x} y={rect.y} width={rect.width} height={rect.height} style={{ strokeWidth: 1, stroke: 'rgba(255, 81, 72,0.1)', fill: fillOpacity(dataForGrid[text.value]) }} />
                        <rect x={rect.x} y={rect.y} width={rect.width} height={rect.height} style={{ strokeWidth: rect.style.strokeWidth, /*stroke: rect.style.stroke*/stroke: 'black', cursor: rect.style.cursor, fill: fillOpacityOfLayer(dataForGrid[text.value], rect.pixel_val, selectedChildPixels) }} />
                        {/* <text fontSize="0.3em" x={text.x - 5} y={text.y - 10} transform={text.transform} opacity="1" >{dataForGrid[text.value]}</text> */}
                    </g>
                )
            })}
        </svg >
    )
}